import React, { useState } from "react";
import PlaybookforContractors from "../components/_Landing/PlaybookForContractors3";
import SEO from "../components/Seo";

const Services = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return <PlaybookforContractors />;
};

export default Services;

export const Head = () => (
  <SEO
    title={"From BIM to Build | Integrated BIM Services in Singapore"}
    description={
      "Tap on our BIM professionals, technology, and workflows for your next project. Deliver better projects on-time and within budget with our project-based 4D, 5D, & 6D BIM services."
    }
  />
);
